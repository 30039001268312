<template>
  <div>
    <AppSubHeader
      link='datos-crediticios'
      title='Mis datos créditicios'
    />
    <!--found data-->
    <div
      class="m-grid__item m-grid__item--fluid m-wrapper"
      v-if="countConcatKeys()"
    >
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body m-portlet__body--no-padding">
          <div class="row">
            <div class="col-12 col-xl-6">
              <div class="row m-row--no-padding m-row--col-separator-xl">
                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Saldo total de créditos activos'"
                    :mainData="formatPrice(creditScoreLast['active_loans_balance'])"
                  />
                </div>
                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Saldo total de créditos con mora'"
                    :mainData="formatPrice(creditScoreLast['default_loans_balance'])"
                  />
                </div>

                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Cantidad de entidades con saldo al día'"
                    :mainData="creditScoreLast['num_nodefault_ent']"
                  />
                </div>
                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Cantidad de entidades con saldo en mora'"
                    :mainData="creditSumDefault"
                  />
                </div>

                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Cantidad de productos al día'"
                    :mainData="creditScoreLast['num_nodefault_product']"
                  />
                </div>
                <div class="col-md-6">
                  <DashBoardSingleCard
                    :title="'Cantidad de productos en mora'"
                    :mainData="creditScoreLast['num_default_product']"
                  />
                </div>
              </div>
            </div>
            <!-- Begin:: Número de entidades con saldo  -->
            <div class="col-12 col-xl-6">
              <TimeSeries
                v-if="countNotNull(Object.keys(BALANCE_KEYS)) > 1"
                :x="creditScoreData['date']"
                :y="creditScoreBalances"
                :labels="Object.values(BALANCE_KEYS)"
                :xaxis="'Fecha'"
                :yaxis="'Monto'"
                :title="'Saldo de créditos'"
                :format="'$,'"
              />
              <BarChart
                v-else
                :x="creditScoreData['date']"
                :y="creditScoreBalances"
                :axis="'Fecha'"
                :yaxis="'Monto'"
                :title="'Saldo de créditos'"
                :labels="Object.values(BALANCE_KEYS)"
                :format="'$,'"
              />
            </div>
            <!-- End:: Número de entidades con saldo  -->
          </div>
          <div class="row">
            <!-- Begin:: Número de entidades con saldo  -->
            <div class="col-12 col-xl-6">
              <AreaChart
                v-if="countNotNull(Object.keys(NUM_ENTITY_KEYS)) > 1"
                :input_data="creditScoreData"
                :x_key="'date'"
                :y_keys="NUM_ENTITY_KEYS"
                :xaxis="'Fecha'"
                :yaxis="'Número de entidades'"
                :title="'Entidades con saldo'"
              />
              <BarChart
                v-else
                :x="creditScoreData['date']"
                :y="numBalanceEntities"
                :axis="'Fecha'"
                :yaxis="'Número de entidades'"
                :title="'Entidades con saldo'"
                :labels="Object.values(NUM_ENTITY_KEYS)"
              />
            </div>
            <!-- End:: Número de entidades con saldo  -->
            <!-- Begin:: Número de productos con saldo  -->
            <div class="col-12 col-xl-6">
              <BarChart
                :x="creditScoreData['date']"
                :y="numBalanceProduct"
                :axis="'Fecha'"
                :yaxis="'Número de productos'"
                :title="'Productos activos'"
                :labels="Object.values(NUM_PRODUCT_KEYS)"
                :mode="'stack'"
              />
            </div>
            <!-- End:: Número de productos con saldo  -->
          </div>
        </div>
      </div>
    </div>
    <!--No found data-->
    <main
      class="m-content container mb-5"
      v-else
    >
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="m-portlet m-portlet--full-height ">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <strong v-if="anyPayment">No hay información disponible</strong>
                    <strong v-else>Para ver esta información realice el pago de la primera cuota</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import AreaChart from "@/components/dashboard/AreaChart.vue";
import BarChart from "@/components/dashboard/BarChart.vue";
import TimeSeries from "@/components/dashboard/TimeSeries.vue";
import DashBoardSingleCard from "@/components/dashboard/SingleCard.vue";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: 'UserCreditScorePage',
  components: {
    AppSubHeader,
    AreaChart,
    BarChart,
    TimeSeries,
    DashBoardSingleCard
  },
  computed: {
    ...mapState({
      creditScoreData: state => state.creditScore.details,
      BALANCE_KEYS: state => state.creditScore.BALANCE_KEYS,
      NUM_ENTITY_KEYS: state => state.creditScore.NUM_ENTITY_KEYS,
      NUM_PRODUCT_KEYS: state => state.creditScore.NUM_PRODUCT_KEYS,
    }),
    ...mapGetters({
      anyPayment: "users/anyPayment",
      numBalanceProduct: "creditScore/numBalanceProduct",
      creditScoreBalances: "creditScore/balances",
      numBalanceEntities: "creditScore/numBalanceEntities",
    }),
    creditScoreLast: function () {
      return this.creditScoreData ? this.creditScoreData["last"] : {}
    },
    creditSumDefault: function () {
      if (this.creditScoreLast['num_default_ent_qnt'] === null && this.creditScoreLast['num_default_ent_noqnt'] === null) {
        return null;
      }
      return this.creditScoreLast['num_default_ent_qnt'] + this.creditScoreLast['num_default_ent_noqnt'];
    },
    countScoredata: function () {
      return (this.creditScoreData['date']) ? this.creditScoreData['date'].length : 0
    },
  },
  methods: {
    formatPrice (value) {
      if (value == null) {
        return null;
      }
      const val = (value / 1).toFixed(0).replace('.', ',')
      return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    countNotNull (keys) {
      const creditScoreData = this.creditScoreData;
      const countValues = keys.map(function (key) {
        if (!creditScoreData[key]) {
          return 0;
        }
        return creditScoreData[key].filter(x => x != null).length;
      });
      const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length;
      return arrAvg(countValues);
    },
    countConcatKeys () {
      return this.countNotNull(
        Object.keys(this.BALANCE_KEYS).concat(Object.keys(this.NUM_ENTITY_KEYS)).concat(Object.keys(this.NUM_PRODUCT_KEYS)));
    }
  },
  created () {
    this.$store.dispatch("creditScore/getDetails");
  },
  watch: {
    anyPayment: {
      handler: function (val, oldVal) {
        if (val && val != oldVal && !Object.keys(this.creditScoreData).length) {
          this.$store.dispatch("creditScore/getDetails");
        }
      }
    }
  },
}

</script>