<template>
<div class="m-subheader ">
	<div class="d-flex align-items-center">
		<div class="mr-auto">
			<!-- <h3 class="m-subheader__title m-subheader__title--separator">Dashboard</h3> -->
			<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
				<li class="m-nav__item m-nav__item--home">
					<router-link to="/" class="m-nav__link m-nav__link--icon">
						<i class="m-nav__link-icon la la-home"></i>
					</router-link>
				</li>
				<li class="m-nav__separator">-</li>
				<li class="m-nav__item">
					<router-link to="/" class="m-nav__link">
						<span class="m-nav__link-text">Dashboard</span>
					</router-link>
				</li>
				<li class="m-nav__separator">-</li>
				<li class="m-nav__item">
					<router-link :to = "link" class="m-nav__link">
						<span class="m-nav__link-text">{{title}}</span>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</div>
<!-- END: Subheader -->
</template>

<script>

export default {
    name: 'AppSubHeader',
    props:{
        link:String,
        title: String
    }
}

</script>