<template>
    <div ref="bar"></div>
</template>

<script>

import Plotly from 'plotly.js-dist';

export default {
    name: "DashBoardBarChart",
    props:{
        x: {
            type: Array,
            default: () => ([])
        },
        y: {
            type: Array,
            default: () => ([])
        },
        title:{
            type:String,
            default: ""
        },
        xaxis:{
            type:String,
            default: ""
        },
        yaxis:{
            type:String,
            default: ""
        },
        labels:{
            type: Array,
            default: () => ([])
        },
        mode: {
            type: String,
            default:""
        },
        format:{
            type:String,
            default: ""
        },
    },
    computed:{
        /**
         * Get traces from x and y values
         */
        traces: function(){
            if(this.x && this.x.length && this.y && this.y.length){

                if (typeof this.y[0]  == "number"){
                    return [{x: this.x, y: this.y, type: "bar"}];
                }
                let x = this.x;
                let labels = this.labels;
                let traces = this.y.map(function(y, index){
                    return {x: x, y:y, type: "bar", name:labels[index]};
                });
                if (x.length < 2){
                    this.layout["barmode"] = "";
                }
                return traces;
            }else{
                return [];
            }
        }
    },
    data(){
         return {
             layout :{
                "title": {
                    "text": this.title,
                    "font": {
                        "family": 'Courier New, monospace',
                        "size": 18
                        },
                    "xref": 'paper',
                },
                "xaxis": {
                    "title": {
                    "text": this.xaxis,
                    "font": {
                        "family": 'Courier New, monospace',
                        "size": 14,
                        "color": '#7f7f7f'
                        }
                    },
                },
                "yaxis": {
                    "title": {
                    "text": this.yaxis,
                    "font": {
                        "family": 'Courier New, monospace',
                        "size": 14,
                        "color": '#7f7f7f'
                        }
                    },
                    "tickformat": this.format,
                },
                "barmode": this.mode
            },
            conf:{
                "displayModeBar": false
            },
         }

    },
    watch:{    
        x: {
            handler: function() {
                Plotly.react(
                this.$refs.bar,
                this.traces,
                this.layout,
                this.conf
                );
            },
            deep: true
        }
    },
    mounted(){
        Plotly.newPlot(
            this.$refs.bar,
            this.traces,
            this.layout,
            this.conf
        );
    }
}

</script>