<template>
    <div class="m-widget1">
        <div class="m-widget1__item">
            <div class="row m-row--no-padding align-items-center text-center">
                <div class="col-12"  @click.prevent="$emit('download-data')">
                    <h3 class="m-widget1__title">{{title}}</h3>
                </div>
                <div class="col-12">
                    <span class="m-widget1__number m--font-brand">{{mainDataCal}}</span>
                </div>
                <div class="col-12" v-if="auxData">
                    <span class="m-widget1__desc">{{auxData}}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "DashBoardSingleCard",
    props:{
        title: {
            type: [String, Number],
            default: ""
        },
        mainData: {
            type: [String, Number],
            default: ""
        },
        auxData: {
            type: [String, Number],
            default: ""
        },
    },
    computed:{
        mainDataCal: function () {
            return (this.mainData === "" || this.mainData == null)?"Sin información":this.mainData;
        }
    }
}
</script>

<style scoped lang="scss">

    .m-widget1__title{
        cursor:pointer;
    }

</style>