<template>
    <div ref="line"></div>
</template>

<script>

import Plotly from 'plotly.js-dist';

export default {
    name: "DashBoardTimeSeries",
    props:{
        x: {
            type: Array,
            default: () => ([])
        },
        y: {
            type: Array,
            default: () => ([])
        },
        title:{
            type:String,
            default: ""
        },
        xaxis:{
            type:String,
            default: ""
        },
        yaxis:{
            type:String,
            default: ""
        },
        tickets: {
            type: Object,
            default: () => ({})
        },
        labels: {
            type: Array,
            default: () => ([])
        },
        format:{
            type:String,
            default: ""
        },
    },
    computed:{
        layout_tick:function(){
            if(this.tickets && Object.keys(this.tickets).length){
                if(this.tickets.y &&  Object.keys(this.tickets.y).length){
                    this.layout["yaxis"]["tickvals"] = Object.values(this.tickets.y);
                    this.layout["yaxis"]["ticktext"] = Object.keys(this.tickets.y);
                }
            }
            return this.layout;   
        },
        /**
         * Get traces from x and y values
         */
        traces: function(){
            if(this.x && this.x.length && this.y && this.y.length){
                if (typeof this.y[0]  == "number"){
                    return [{
                        x: this.x, y: this.y, type: "scatter", mode: "lines+markers"}];
                }
                let x = this.x;
                let labels = this.labels;
                let traces = this.y.map(function(y, index){
                    return {
                        x: x, y:y, type: "scatter",  mode: "lines+markers", 
                        name:labels[index]};
                });
                return traces;
            }else{
                return [];
            }
        }
    },
    data(){
         return {
             layout :{
                "title": {
                    "text": this.title,
                    "font": {
                        "family": 'Courier New, monospace',
                        "size": 18
                        },
                    "xref": 'paper',
                },
                "xaxis": {
                    "title": {
                        "text": this.xaxis,
                        "font": {
                            "family": 'Courier New, monospace',
                            "size": 14,
                            "color": '#7f7f7f'
                        }
                    },
                    "type": 'date',
                },
                "yaxis": {
                    "title": {
                        "text": this.yaxis,
                        "font": {
                            "family": 'Courier New, monospace',
                            "size": 14,
                            "color": '#7f7f7f'
                        }
                    },
                    "rangemode": 'nonnegative',
                    "nticks": 10,
                    "tick0":0,
                    "tickformat": this.format,
                }
            },
            conf:{
                "displayModeBar": false
            }
         }
    },
    watch:{    
        x: {
            handler: function() {
                Plotly.react(
                this.$refs.line,
                this.traces,
                this.layout_tick,
                this.conf
                );
            },
            deep: true
        }
    },
    mounted(){
        Plotly.newPlot(
            this.$refs.line,
            this.traces,
            this.layout_tick,
            this.conf
        );
    }
}

</script>